#blockcart-modal {

  .product-name {
    color: #9b1740;
    font-size: 1.1rem;
  }
  .modal-footer {

    button {
      border-color: #9b1740;
      color: #9b1740;
      border-radius: 6px;
      transition: all .3s;

      &:hover {
        background: #9b1740;
        color: #fff;
      }
    }
    a {
      background: #9b1740;
      border-color: #9b1740;
      box-shadow: none;
      border-radius: 6px;
      transition: all .3s;

      &:hover {
        background: none;
        color: #9b1740;
      }
    }
  }
}

.quickview {

  .product-layer-zoom {
    display: none !important;
  }

  .product-variants .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #9b1740;
    border-color: #9b1740;
  }

  .product-variants .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
  }

  .product-add-to-cart .add .btn-add-to-cart {
    box-shadow: none;
    border-color: #9b1740;
    background: #9b1740;
    border-radius: 6px;
    transition: all .3s;

    &:hover {
      background: none;
      color: #9b1740;
    }
  }

  .modal-footer {
    .social-sharing, .ask-button {
      display: none !important;
    }
  }
}

#cart {
  #main {
    margin-top: 2rem;
  }
}

#checkout {
  #content {
    margin-top: 2rem;
  }
}

#cart {

  .card {

    .card-header {
      font-family: Overpass, sans-serif;
      text-transform: none;
    }
    .remove-from-cart {
      i {
        color: #9b1740;

        &:hover {
          opacity: .7;
        }
      }
    }
  }
  .btn-continue {
    border-color: #9b1740;
    color: #9b1740;
    box-shadow: none !important;

    &:hover {
      background: #9b1740;
      color: #fff;
    }
  }
  .btn-checkout {
    a {
      box-shadow: none !important;
      background: #9b1740;
      border-color: #9b1740;

      &:hover {
        background: none;
        color: #9b1740;
      }
    }
  }

  .card-footer {
    button {
      box-shadow: none !important;
      background: #9b1740;
      border-color: #9b1740;
    }
  }
}


#checkout {

  .checkout-process {
    .step-title {
      font-family: Overpass, sans-serif;
      text-transform: none;
    }
    .nav-item {

      .nav-link {
        font-family: Overpass, sans-serif;
      }

      .active {
        color: #9b1740;
        border-bottom: 3px solid #9b1740;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      box-shadow: none;
      background: #9b1740;
      border-color: #9b1740;
    }

    .form-group {

      .label {
        font-family: Overpass, sans-serif;
        color: #000;
      }

      .form-control {
        font-family: Overpass, sans-serif;
        color: #000;
        border-radius: 6px;
      }
      .form-control:focus {
        border-color: #9b1740;
        box-shadow: none;
      }
      .forgot-password {
        a {
          color: #9b1740;

          &:hover {
            color: #000;
          }
        }
      }
    }
    .continue {
      box-shadow: none !important;
      background: #9b1740;
      border-color: #9b1740;
      font-family: Overpass, sans-serif;
      font-weight: 700;
      border-radius: 6px;

      &:hover {
        background: none;
        color: #9b1740;
      }
    }
    .card-body {
      a {
        color: #9b1740;

        &:hover {
          color: #000;
        }
      }
      .text-underline {
        color: #9b1740 !important;
      }
      .delivery-options {

        .delivery-option {
          .carrier-price {
            color: #000;
          }
        }
      }
    }
    #payment-confirmation {
      button {
        box-shadow: none !important;
        background: #9b1740;
        border-color: #9b1740;

        &:hover {
          opacity: .7;
        }
      }
    }
  }
  .order-checkout {
    .link__showsummary {
      color: #9b1740;

      &:hover {
        color: #000;
      }
    }
  }


  #js-checkout-process {
    .card-footer {
      .text-muted {
        color: #9b1740 !important;

        &:hover {
          color: #000 !important;
        }
      }
    }
  }

  .payment-options .alert {
    background: #326647;
    border-color: #326647;
    color: #fff;

    a {
      color: #f3f311;

      &:hover {
        color: #9b1740;
      }
    }
  }
  #conditions-to-approve {
    background: #326647;
    border-color: #326647;
    color: #fff;

    a {
      color: #f3f311;

      &:hover {
        color: #9b1740;
      }
    }
  }
}


#order-confirmation {

  #content-hook_payment_return {
    a {
      color: #9b1740;

      &:hover {
        color: #000;
      }
    }
  }
}