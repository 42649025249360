.l-header {
  background-color: $header-bg;
  margin-bottom: $spacer;
  box-shadow: $header-box-shadow;
}

.header-nav {
  border-bottom: 1px solid $header-nav-border-color;
  background-color: $header-nav-bg-color;
  color: $header-nav-color;
  padding-top: $header-nav-padding-y;
  padding-bottom: $header-nav-padding-y;

  & a:not(.dropdown-item), .btn {
    color: $header-nav-color;
  }
}

.header-top {
  border-bottom: 1px solid $header-top-border-color;
}

@include media-breakpoint-mobile {
  .header__container {
    padding-left: 0;
    padding-right: 0;
  }
  .logo {
    width: 100%;
    max-width: 115px;
  }
}


#header {
  background: #eeeeee !important;
  margin-bottom: 0;

  .header-nav {
    border-bottom: none;

    .displayNav1 {

      .displayNav2 {


        #google_translate_element {

          .goog-te-gadget-simple {
            background: none;
            border: none;

            .goog-te-gadget-icon {
              display: none;
            }

            .goog-te-menu-value {
              span {
                border-left: none !important;
                font-size: .875rem;
                font-weight: 400;
                color: #000 !important;
              }
            }
          }
        }
      }
    }
  }

  .header-top {
    padding-bottom: 1rem;
    border-bottom: none;
  }


  .displayTop {
    padding-left: 2rem;

    .menu {
      margin-right: 1rem;

      li {
        padding: 0 .5rem;

        a {

          transition: all .3s;

          &:hover {
            span {
              color: #9b1740;
            }
          }

          span {
            text-transform: none;
            color: #000;
            font-size: .9375rem;
            font-weight: 700;
          }
        }
      }
    }

    #_desktop_user_info {

      display: flex;
      align-items: center;
      padding-bottom: .2rem;

      .user-info {
        position: relative;
        display: grid;


        .account:hover, a:hover {
          span {
            color: #9b1740;
          }
          svg path {
            stroke: #9b1740;
          }
        }

        &:before {
          content: "";
          width: 1px;
          height: 14px;
          background: #000;
          opacity: .2;
          position: absolute;
          left: -.8rem;
          top: 4px;
        }

        &:after {
          content: "";
          width: 1px;
          height: 14px;
          background: #000;
          opacity: .2;
          position: absolute;
          right: -.8rem;
          top: 4px;
        }

        .logout {
          display: flex;
          color: #000;

          &:hover {
            color: #9b1740;

            svg path {
              stroke: #9b1740;
            }
          }
        }
      }

      span {
        color: #000;
        font-size: .9375rem;
        font-weight: 700;
      }
    }

    .favorite-block {
      display: flex;
      align-items: center;
      padding-bottom: .4rem;
    }

    .header-favorite {

      align-items: center;
      padding-bottom: .2rem;
      margin-left: 1.3rem;

      &:hover {
        span, .header-t {
          color: #9b1740;
        }

        svg path {
          stroke: #9b1740;
        }
      }

      .header-ico {
        position: relative;
        margin-right: .5rem;


        span {
          position: absolute;
          top: .7rem;
          left: .7rem;
        }
      }

      span, .header-t {
        color: #000;
        font-size: .9375rem;
        font-weight: 700;
      }

      .header-t {
        display: flex;
        align-self: flex-end;
      }
    }


    .blockcart {
      background: #9b1740;
      border-radius: 10px;
      color: #fff;
      font-size: .9375rem;
      font-weight: 700;
      padding-left: .8rem;
      padding-right: .8rem;
      min-width: 138px;
      position: relative;

      span {
        color: #fff;
      }

      .shopping-cart {

        align-items: center;
        justify-content: space-between;

        .basket-icon {
          position: relative;

          .cart-products-count {
            position: absolute;
            top: .8rem;
            left: .8rem;
          }
        }

        .blockcart__label {
          font-size: .9375rem;
          font-weight: 700;
        }

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
      }
    }
  }
}