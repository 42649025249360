.product-miniature {

  padding: 0 20px;

  .card-product {
    border: none;
    background: none;
    position: relative;

    &:hover .product-description .product-title a {
      color: #9b1740 !important;
    }

    .card-img-top {
      overflow: visible;
    }

    .card-img-top a {
      background: none;
      background-image: url("../img/tla/product-bg.png") !important;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      position: relative;
      overflow: visible;

      img {
        position: absolute;
        bottom: -3rem;
      }
    }

    .card-body {
      position: relative;
      padding: 2rem 0 1.25rem 0;

      .product-description {
        justify-content: normal;
        .product-title {
          text-align: left;
          margin-bottom: 0;
          a {
            color: #000;
            font-weight: 700;
            font-size: 1.125rem;
            font-family: Overpass, sans-serif;
          }
        }
        .index-mini {
          font-family: Overpass, sans-serif;
          color: #000;
          font-size: .875rem;
          font-weight: 300;
        }
        .product-price-and-shipping {
          text-align: left !important;

          .price {
            color: #9b1740;
            font-size: 1.5rem;
            font-weight: 700;
            font-family: Overpass, sans-serif;
          }
        }
        .add-to-cart-mini {

          padding: .5rem 0 !important;
          .quick-view {
            color: #fff;
            font-weight: 700;
            font-size: 1rem;
            font-family: Overpass, sans-serif;
            background: #9b1740;
            padding: .6rem 1.5rem;
            border-radius: 6px;
            transition: all .3s;

            &:hover {
              color: #9b1740;
              background: #fff;
            }
          }
        }
        .quick-view {
          padding: .6rem 4rem !important;
        }
      }
    }
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem .75rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background: none;

  .breadcrumb-item {
    a {
      color: #d2879e;
      font-family: Overpass, sans-serif;
      font-size: .8125rem;
      font-weight: 300;

      &:hover {
        color: #fff;
      }
    }
  }
  .active {

    &:before {
      color: #fff;
      content: "-";
      padding-right: .2rem;
    }

    span {
      color: #fff;
      font-family: Overpass, sans-serif;
      font-size: .8125rem;
      font-weight: 300;
    }
  }
}


#category {

  #wrapper {
    .breadcrumb {
      display: none;
    }
    #content-wrapper {
      .breadcrumb {
        display: flex;
        flex-wrap: wrap;
        padding: .75rem 1rem .75rem 0;
        margin-bottom: 1rem;
        list-style: none;
        background: none;

        .breadcrumb-item {
          a {
            color: #d2879e;
            font-family: Overpass, sans-serif;
            font-size: .8125rem;
            font-weight: 300;

            &:hover {
              color: #fff;
            }
          }
        }
        .active {

          &:before {
            color: #fff;
            content: "-";
            padding-right: .2rem;
          }

          span {
            color: #fff;
            font-family: Overpass, sans-serif;
            font-size: .8125rem;
            font-weight: 300;
          }
        }
      }
    }
  }

  #products {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -8rem;
      left: -45rem;
      width: 130vw;
      height: 100%;
      background-image: url("../img/tla/category-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -2;
    }

    #js-product-list-top {
      display: none;
    }
  }

  #js-product-list {

    .showing {
      display: none;
    }

    .category-product-title {
      font-family: Overpass, sans-serif;
      color: #000;
      font-size: 1.875rem;
      font-weight: 700;
      margin-bottom: 0;
    }
    .separator {
      margin: 0 0 2rem 0;
    }
  }

  .products {
    .product-miniature {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0 15px;
      margin-bottom: 4rem !important;

      .card {
        .card-img-top {
          .thumbnail {
            background-image: url(../img/tla/category-product-bg.png) !important;

            img {
              width: auto !important;
              height: auto !important;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
        .card-body {
          padding: 3rem 0 1.25rem 0;

          .index-mini {
            margin-bottom: 0;
          }
        }
      }
    }
    .product-miniature:last-child, .product-miniature:nth-last-child(2) {
      margin-bottom: 0 !important;
    }
  }
}
@media (max-width: 1366px) {
  #category .block-category:before {
    left: -12vw;
  }
}


.block-category {
  position: relative;
  background: none;
  border: none;
  color: #fff;

  &:before {
    content: "";
    position: absolute;
    top: -3rem;
    left: -25vw;
    width: 110vw !important;
    height: 100%;
    background-image: url("../img/tla/category-header-bg.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .h1 {
    font-family: Overpass, sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: none;
    margin-top: 2rem;
    position: relative;
  }

  .category-separator {
    width: 5%;
    height: 1px;
    background: #fff;
  }

  #category-description {
    font-family: Overpass, sans-serif;
    font-size: 1.063rem;
    color: #fff !important;
    line-height: 1.6rem;
  }

  .category-description {
    #category-description {
      padding: 0 5rem 0 0;
    }
    .category-cover {
      text-align: right;
      position: relative;
      bottom: 6rem;
    }
  }
}