.comments_note {
  display: none;
}

#product #wrapper #content-wrapper {
  .featured-products {
    .product-miniature {
      padding: 0 15px;
      .comments_note {
        display: none;
      }
    }
  }
}

#product {
  .breadcrumb {
    display: none;
  }

  #wrapper {
    margin-top: 3rem;

    #content-wrapper {

      .left-side {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          width: 100vw;
          height: 100%;
          top: 15rem;
          left: 15rem;
          background-image: url(../img/tla/product-big-background.png);
          background-size: cover;
          z-index: -1;
        }
      }

      .comments_note {

        width: 100% !important;
        display: flex;
        position: relative;
        align-items: center;
        border-top: 2px solid #e7e7e7;
        border-bottom: 2px solid #e7e7e7;
        justify-content: space-between;
        margin-bottom: 1rem;

        .star_content {

          display: flex;
          justify-content: center;
          padding-right: 1rem;
        }

        .star.star_on {
          background-image: url(../img/tla/star.svg) !important;
          background-position: center !important;
        }

        .star {
          background-image: url(../img/tla/empty-star.svg) !important;
          background-position: center !important;
        }

        span {
          display: flex;
          align-items: center;
          position: relative;
          font-family: Overpass, sans-serif;
          font-size: 1rem;
          color: #171717;
          line-height: 100%;
          text-align: center;
          padding: 0 1rem;

          img {
            padding-right: .6rem;
          }
        }

        a {
          position: relative;

          img {
            padding-right: .6rem;
          }
        }

        .open-comment-form {
          background: none;
          font-family: Overpass, sans-serif;
          font-size: 1rem;
          color: #171717;
          box-shadow: none;
          margin-bottom: 0;
          padding-left: 1rem;
          border: none;
        }

        a {
          padding-left: 1rem;
          background: none;
          font-family: Overpass, sans-serif;
          font-size: 1rem;
          color: #171717;
          box-shadow: none;
          margin-bottom: 0;
          border: none;
          transition: all .5s;

          &:hover {
            color: #9b1740 !important;
          }
        }

        .print {
          display: none;
          list-style: none;
        }
      }

      .h1 {
        font-family: Overpass, sans-serif;
        font-size: 1.375rem;
        font-weight: 700;
        color: #000;
        text-transform: none;
      }

      .product-information {

        .products-description-short {
          margin-bottom: 4rem;
        }

        .product-actions {
          border-top: 1px solid #adb5bd;
        }

        .product-for {
          font-family: Overpass, sans-serif;
          font-size: 1rem;
          font-weight: 300;
          color: #000;
        }

        .product-short-desc {
          font-family: Overpass, sans-serif;
          font-size: 1rem;
          font-weight: 300;
          color: #000;
        }

        .product-variants {
          margin-top: 1.5rem;
          .product-variants-item {
            display: flex;

            label {
              font-family: Overpass, sans-serif;
              font-size: .9375rem;
              font-weight: 700;
              color: #000;
              display: flex;
              align-items: center;
              margin-bottom: 0;
              width: 24%;
            }

            .custom-control {
              width: 25%;

              .custom-control-label {
                font-family: Overpass, sans-serif;
                font-size: 1rem;
                font-weight: 400;
                color: #000;
              }

              .custom-control-label::before {
                width: 1.7rem;
                height: 1.7rem;
                background: none;
                box-shadow: none;
                top: -.2rem;
                left: -2rem;
              }

              .custom-control-label::after {
                width: 1.5rem;
                height: 1.5rem;
              }

              .custom-control-input {

                box-shadow: none;

                &:checked ~ .custom-control-label:before {
                  color: #9b1740;
                  border-color: #adb5bd;
                  background: none;
                  border-radius: 50%;
                  width: 1.7rem;
                  height: 1.7rem;
                }

                &:checked ~ .custom-control-label:after {
                  background-image: none;
                  background: #9b1740;
                  border-radius: 50%;
                  width: 1.2rem;
                  height: 1.2rem;
                  top: .05rem;
                  left: -1.75rem;
                }

                &:focus ~ .custom-control-label:before {
                  box-shadow: none;
                  top: -.2rem;
                  left: -2rem;
                }
              }
            }
            .select-variant-item-box {
              width: 80%;
              .custom-select {
                width: 30% !important;
                font-family: Overpass, sans-serif;
                font-size: 1rem;
                font-weight: 400;
                background-color: transparent;
                border-radius: 6px;
                box-shadow: none;

                &:focus {
                  border-color: #adb5bd;
                }
              }
            }
          }
        }

        .product-prices {
          display: flex;

          .label {
            font-family: Overpass, sans-serif;
            font-size: .9375rem;
            font-weight: 700;
            color: #000;
            display: flex;
            align-items: center;
            margin-bottom: 0;
            width: 24%;
          }

          .price {
            font-family: Overpass, sans-serif;
            font-size: 1.875rem;
            font-weight: 700;
            color: #9b1740;
          }

          .tax-shipping-delivery-label {
            display: none;
          }
        }

        .add-cart-fav-block {
          display: flex;
          justify-content: space-between;

          .product-add-to-cart {


            .product-minimal-quantity {
              display: none;
            }

            .product-quantity {
              flex-wrap: nowrap;
              border-radius: 10px;
              border: 2px solid #9b1740;
              margin-top: 0;
            }

            .qty {
              margin: 0;

              .input-group {
                box-shadow: none;
                border: none;
              }

              button, input {
                background: none;
                color: #9b1740;
                font-family: Overpass, sans-serif;
                font-size: 1rem;
                font-weight: 700;
              }
            }
            .add {
              margin-top: 0;

              .btn-add-to-cart {
                background: #9b1740;
                border-radius: 6px;
                border-color: #9b1740;
                box-shadow: none;
                transition: all .3s;

                &:hover {
                  opacity: .7;
                }
              }
            }
          }
          .fav {
            display: flex;
            align-items: center;
            border-radius: 6px;
            border: 2px solid #000;
            padding: 0 1rem;
            transition: all .3s;

            a {
              transition: all .3s;

              svg path {
                transition: all .3s;
              }
            }

            &:hover {
              background: #9b1740;
              a {
                color: #fff;

                svg path {
                  stroke: #fff;
                }
              }
            }

            a {
              color: #000;
              font-family: Overpass, sans-serif;
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }
        .product-additional-info {
          display: flex;
          flex-wrap: wrap;

          .social-sharing {
            padding: 0;
            order: 1;

            .social-share-btn--product.facebook:hover {
              background-image: url(../img/facebook-pink.svg);
            }
            .social-share-btn--product.twitter:hover {
              background-image: url(../img/twitter-pink.svg);
            }
            .social-share-btn--product.googleplus:hover {
              background-image: url(../img/gplus-pink.svg);
            }
            .social-share-btn--product.pinterest:hover {
              background-image: url(../img/pinterest-pink.svg);
            }
          }

          .mailalerts-not {
            order: -1;
            margin-top: 1rem;
            padding: 0;

            .form-control {
              border-radius: 6px;
              box-shadow: none;
              border: 2px solid #9b1740;
            }

            button {
              background: #9b1740;
              border: 1px solid #9b1740;
              box-shadow: none;
              font-family: Overpass, sans-serif;
              transition: all .3s;

              &:hover {
                background: transparent;
                color: #000;
              }
            }
          }

          .ask-button, .shipment-24, .print {
            display: flex;
            align-items: center;
            margin-top: 1.5rem;
          }
          .ask-button {

            .product-ask {
              &:hover {
                span {
                  color: #9b1740;
                }
                svg {
                  path {
                    fill: #9b1740;
                  }
                }
              }

              #show-form {
                background: none;
                box-shadow: none;
                border: none;
                padding: 0;

                svg {
                  margin-right: .5rem;
                }
              }
            }
          }
          .shipment-24 {
            display: flex;
            order: -1;
            padding-left: 0;

            svg {
              margin-right: .5rem;
            }
            p {
              margin-bottom: 0;
            }
          }
          .print {
            a {
              color: #000;

              &:hover {
                color: #9b1740;

                svg {
                  path {
                    fill: #9b1740;
                  }
                }
              }
            }
          }
        }
        #productAsk {
          .new_comment_form_content {

            & > .col-12 {
              .row {
                justify-content: space-between;
              }
            }

            .col-xl-6:last-child {
              flex: 0 0 30%;
              max-width: 30%;

              button {
                background: #9b1740;
                border: 1px solid #9b1740;
                transition: all .3s;

                &:hover {
                  background: none;
                  color: #9b1740;
                }
              }
              .modal-no {
                color: #000;
                transition: all .3s;

                &:hover {
                  color: #9b1740;
                }
              }
            }
          }
        }
      }
      #content {
        .images-container {

          .products-imagescover {
            & > .slick-arrow {
              display: none !important;
            }
          }

          .product-layer-zoom {
            color: #000;

            &:hover {
              color: #9b1740;
            }
          }

          .product-img {
            position: relative;
            padding-bottom: 100px;
            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: calc(100% - 100px);
              top: 0;
              left: 0;
              background-image: url("../img/tla/product-main-bg.png");
              background-size: cover;
            }

            .img-fluid {
              position: relative;
              top: 6rem;
            }
          }
          .product-thumbs {

            .product-thumb {
              border: none;
              .rc--lazyload {
                border-radius: 6px;
                border: 1px solid #e1e1e1;
                background: #fff;
              }
            }
            .slick-current {
              .rc--lazyload {
                border: 1px solid #9b1740;
              }
            }
          }
        }
      }
      .lastReviewsHome {
        margin-top: 3rem;

        #lastReviews {
          padding-bottom: 0;
        }

        .products-section-title {
          color: #fff;
        }
      }
      .tabs {
        border: none;
        color: #fff;
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 5rem;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -50vw;
          width: 100vw;
          height: 100%;
          background: #9b1740;
        }
        &:after {
          content: "";
          position: absolute;
          top: -4rem;
          left: 50%;
          margin-left: -50vw;
          width: 100vw;
          height: 50%;
          background-image: url("../img/tla/product-description-top.png");
          background-repeat: no-repeat;
          background-size: cover;
          z-index: -1;
        }

        .product-description {
          .product-main-desc-block {

            & > .row > .col-md-12 {
              padding-left: 2rem;

              .product-small-desc-block {
                margin-bottom: 2rem;
                .product-small-description-title {
                  font-family: Overpass, sans-serif;
                  font-size: 1.375rem;
                  font-weight: 300;
                  margin-bottom: 1rem;
                  position: relative;

                  &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: -2rem;
                    width: 100%;
                    height: 100%;
                    background-image: url("../img/tla/check.png");
                    background-repeat: no-repeat;
                  }
                }
                .product-main-description {
                  font-family: Overpass, sans-serif;
                  font-size: 1rem;
                  font-weight: 400;
                }
              }
            }

            .product-desc-title {
              z-index: 1;
              font-family: Overpass, sans-serif;
              font-size: 1.875rem;
              font-weight: 700;
              margin-bottom: 2rem;
            }
          }
          .product-main-desc-block2 {
            position: relative;
            color: #000;
            padding: 0;
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: -50vw;
              width: 100vw;
              height: 100%;
              background: #eee;
            }
            .product-desc2 {

              .separator {
                margin-left: 0;
                margin-right: 0;
              }

              .product-desc2-title {
                font-family: Overpass, sans-serif;
                font-size: 1.875rem;
                font-weight: 700;
                margin-top: 1rem;
                margin-bottom: 0;
              }
              .product-desc2-title1 {
                margin-top: 4rem;
              }
              .product-desc2-desc {
                font-family: Overpass, sans-serif;
                font-size: 1rem;
                font-weight: 300;
                margin-top: 1.5rem;
              }
            }
            .size-block-desc {
              padding: 0 15px;
              margin-top: 3rem;
              .product-desc2-title {
                width: 100%;
              }
              .size-desc {
                margin: 2rem 0;

                .col-md-4 {
                  padding: 0 20px;

                  h4 {
                    font-family: Overpass, sans-serif;
                    font-size: 1rem;
                    font-weight: 700;
                  }
                  p {
                    font-family: Overpass, sans-serif;
                    font-size: 1rem;
                    font-weight: 300;
                  }
                }
              }
              .size-block {
                p {
                  font-family: Overpass, sans-serif;
                  font-size: 1rem;
                  font-weight: 700;
                  margin-top: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
  #new_comment_form {
    .fr {
      button {
        background: #9b1740 !important;
        border: 1px solid #9b1740;
        box-shadow: none;
        font-family: Overpass, sans-serif;
        transition: all .3s;

        &:hover {
          background: transparent;
          color: #000;
        }
      }
      a {
        color: #9b1740;
        font-size: 1rem;
        transition: all .3s;

        &:hover {
          color: #000;
        }
      }
    }
  }
}