@media (max-width: 1366px) {
  #index #footer {
    .contact-block-bg:before {
      height: 150% !important;
    }
  }

  #category {

    #wrapper {
      .block-category:before {
        top: -1.5rem;
      }
    }
  }
}


@media (max-width: 1024px) {

  .quickview {
    .modal-dialog {
      max-width: 850px;

      .product-thumbs {
        button {
          transform: none !important;
        }
      }
    }
  }


  #_desktop_top_menu {
    #cms-category-1 {
      display: none;
    }
  }


  #header {

    .displayNav1 {
      .clearBoth {
        display: flex;
        justify-content: flex-end;
      }
    }

    .header-top {
      #_desktop_logo {
        padding: 0;
      }

      .displayTop {
        padding: 0 15px;
        padding-left: 8px;

        li {
          padding: 0;

          a span {
            font-size: .8rem;
          }
        }

        .menu {
          margin-right: 0;
        }
      }

      #_desktop_user_info {

        .user-info:before, .user-info:after {
          display: none;
        }

        a {
          display: flex;

          span {
            font-size: .8rem;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }


      .favorite-block {

        .header-box {
          margin-left: 0;

          .header-ico {
            margin-right: 0;
          }
        }

        .header-t {
          display: none;
        }
      }

      #_desktop_cart {
        margin-left: 0 !important;

        .blockcart {
          min-width: 120px;
        }
      }
    }
  }


  #carousel {
    .caption-description {
      .banner-info {
        .banner-title {
          font-size: 1rem !important;
        }

        .banner-description {
          font-size: 1.4rem !important;
        }
      }
    }
  }

  .heart-block {

    padding-bottom: 2rem;

    &:before {
      width: 100%;
      height: 20%;
    }

    .container {
      flex-wrap: wrap;

      .heartmate-bg:before {
        display: none;
      }

      & > .col-lg-5 {
        padding: 0;

        .heart-content {
          padding: 0;
          justify-content: space-around;

          .right-side {
            img {
              max-width: 130px;
            }
          }
        }
      }

      & > .col-lg-5:before {
        display: none;
      }

      .col-lg-7 {
        .right-side {
          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  .featured-products {
    .products {
      .product-miniature {
        .card-img-top {
          .thumbnail {
            background-size: cover;
          }
        }

        .card-body {
          .product-price-and-shipping {
            margin-top: auto;
          }
        }
      }
    }
  }
  .lastReviewsHome {
    margin-bottom: 0;

    #lastReviews {
      padding: 0;
    }

    .reviewBlock {
      .contents {
        .row {
          flex-wrap: nowrap;

          .review_cotnents {
            width: auto;
            padding: 0 0 6rem 5rem !important;

            .products-section-title {
              padding: 0 !important;
            }

            .who {
              padding: 0 !important;
            }

            .stars {
              padding-top: 0 !important;
            }
          }

          .col-lg-5 {
            display: flex;
            align-items: center;
            justify-content: center;
            //padding: 0;
            a {
              //top: 1rem;
              //left: -3rem !important;
              position: static !important;

              img {
                max-width: 200px;
              }
            }
          }
        }
      }
    }
  }
  .about-products-block {

    .about-products-title {
      font-size: 1.5rem !important;
    }


    .about-product-description {
      font-size: .9rem !important;
    }

    .about-product-items {
      margin-top: 1rem;

      & > .col-md-3 {
        padding: 0;

        .about-product-item {
          h3 {
            font-size: .85rem;
          }

          p {
            padding: 0 .7rem;
          }
        }
      }
    }
  }
  .index-about-us {
    padding-top: 2rem;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    &:before, &:after {
      display: none;
    }

    img {
      max-width: 100%;
      height: auto !important;
    }
  }

  #index #footer {
    margin-top: -2rem !important;
  }

  #footer {

    //background: #9b1740;

    .contact-block-bg:before, .contact-block-bg:after {
      display: none;
    }

    .footer-left-bg {
      display: none;
    }

    .footer-container {
      .container {
        .footer-blocks {
          .cms-page-link, .custom-page-link {
            font-size: .9rem !important;
          }

          .block_newsletter {
            .input-group {
              .form-control {
                font-size: .9rem;
                padding-right: 0;
              }

              #gdpr_consent {
                .custom-control-label {
                  font-size: .8rem !important;
                }
              }
            }
          }

          .block-contact {
            .desc_footer {
              #mail:before, #address:before {
                margin-right: .5rem !important;
              }

              .info {
                .address-info {
                  font-size: .9rem !important;
                }

                .info-description {
                  font-size: .8rem !important;
                }
              }
            }
          }
        }
      }
    }
  }

  #category {
    .block-category {
      padding: 0;

      &:before {
        background: #9b1740;
        top: 0 !important;
      }

      .h1 {
        margin-top: 1rem;
        margin-bottom: 0;
      }

      .breadcrumb {
        margin-bottom: 0 !important;
      }

      .category-cover {
        bottom: 3rem;
      }
    }

    #products {
      margin-top: 2rem;

      &:before {
        display: none;
      }

      .product-miniature {
        .card {
          .thumbnail {
            max-width: 100%;
            background-size: contain;

            img {
              height: 100% !important;
            }
          }
        }
      }
    }
  }

  #product {

    .left-side:before {
      display: none;
    }

    .comments_note {
      span, a {
        font-size: .8rem !important;
      }
    }

    .add-cart-fav-block {
      .fav {
        padding: 0 .5rem !important;

        .favorite_btn {
          span {
            font-size: .8rem;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .product-additional-info {
      .ask-button, .shipment-24, .print {
        font-size: .8rem;
      }
    }

    .tabs {
      &:after {
        display: none;
      }

      .tab-content {
        padding-top: 2rem;
      }

      .size-block-desc {
        .size-block {
          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .images-container {

      .slick-arrow {
        transform: none;
      }
    }
  }

  #cms {
    #content {
      .faq-blocks {
        .faq-block {
          padding: .7rem !important;

          p {
            font-size: .8rem !important;
          }
        }
      }
    }
  }

  #contact {
    .contact-form-info-blocks {
      & > .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }

  #module-x13favoriteproducts-view {
    .breadcrumb {
      .active {
        span {
          color: #000;
        }
      }
    }

    .product-add {
      padding: .75rem 0;

      .add-to-cart {
        padding: .5rem;
        font-size: .8rem;
        font-weight: 700;
      }
    }
  }
}

@media (max-width: 1200px) {
  #header {

    .displayTop {
      padding-left: 0;

      #_desktop_cart {
        margin-left: 0 !important;
      }
    }

    #_desktop_top_menu {
      #top-menu {
        li {
          padding: 0;

          a {
            padding: .4rem;
          }
        }
      }
    }
  }

  #product {
    .add-cart-fav-block {
      .fav {
        padding: 0 .4rem !important;
        .favorite_btn {
          span {
            font-size: .9rem;

            svg {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }

  #header {
    .displayNav1 {
      display: block;

      .displayNav2 {
        max-width: 100%;
      }
    }
  }
}


@include media-breakpoint-down(md) {
  #product {
    .add-cart-fav-block {
      display: block !important;

      .fav {
        margin-top: 1rem;
      }
    }
  }
}


@media (max-width: 768px) {

  .quickview {
    .modal-dialog {
      max-width: 650px;

      .product-thumbs {
        width: 210px;
      }
    }
  }

  #header {
    .displayTop {

      #_desktop_user_info {
        span, a {
          font-size: .8rem;
        }
      }

      #_desktop_cart {
        margin-left: 0 !important;

        .blockcart {
          min-width: 100px;
          padding: .2rem;

          .blockcart__label {
            font-size: .9rem;
          }
        }
      }
    }
  }

  #footer {
    .footer-container {
      .container {
        .footer-blocks {

          .material-icons {
            margin-right: 0 !important;
          }

          .cms-page-link, .custom-page-link {
            font-size: .65rem !important;
          }

          .block_newsletter {
            .input-group {
              .form-control {
                font-size: .8rem;
              }

              #gdpr_consent {
                .custom-control-label {
                  font-size: .7rem !important;
                }
              }
            }
          }

          .block-contact {
            .footer-info {
              .desc_footer {
                #mail:before, #address:before {
                  display: none;
                }
              }

              .facebook-icon {
                margin-right: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  #category {
    .category-description {
      #category-description {
        padding: 0 .5rem 0 0;

        p {
          font-size: .8rem;
        }
      }

      .category-cover {
        width: 40%;
        padding: 0;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  #product {
    .left-side {

      .comments_note {
        .star_content {
          padding-right: .5rem !important;

          .star {
            width: 25px !important;
            height: 25px !important;
          }
        }

        .open-comment-form {
          display: flex;
        }
      }

      .product-variants {
        .custom-control-label {
          font-size: .8rem !important;
        }
      }

      .product-additional-info {
        .ask-button, .shipment-24, .print {
          padding: 0 .2rem;
          font-size: .7rem;

          svg {
            margin-right: .1rem !important;
            width: 16px;
            height: 16px;
          }
        }

        .social-sharing {
          span {
            font-size: .8rem;
          }

          .social-share-btn {
            width: 2rem;
            height: 2rem;
          }
        }
      }

      #productAsk {
        .custom-control-label {
          font-size: .8rem;
        }
      }
    }

    #content {
      .images-container {

        .product-thumbs {
          width: 210px;
        }

        .slick-arrow {
          transform: none;
        }
      }
    }

    .tabs {
      margin-top: 0 !important;

      .product-desc-title {
        font-size: 1.5rem !important;
      }

      .product-small-description-title {
        font-size: 1.2rem !important;
      }


      .product-main-description {
        font-size: .9rem !important;
      }


      .product-main-desc-block2 {
        .product-desc2-title1 {
          margin-top: 2rem !important;
          font-size: 1.5rem !important;
        }

        .product-desc2-desc {
          font-size: .9rem !important;
        }

        .product-desc2-title {
          font-size: 1.5rem !important;
        }

        .size-desc {
          h4, p {
            font-size: .9rem !important;
          }
        }

        .size-block {
          p {
            font-size: .9rem !important;
          }
        }
      }
    }


    #blockcart-modal {
      .modal-dialog {
        max-width: 600px;
      }
    }
  }

  #cms {
    .faq-blocks {
      .faq-block-img {
        height: 50px !important;

        img {
          max-width: 20px !important;
          max-height: 20px;
        }
      }
    }

    .accordion-container {
      .faq-title {
        font-size: 2rem;
      }

      .accordion-box {
        .accordion-header {
          font-size: 1.1rem;
        }

        .accordion-content {
          p {
            font-size: .9rem;
          }
        }
      }
    }
  }
}


@media (max-width: 767px) {

  #footer .col-md-3:nth-child(1) .collapse:not(.show) {
    display: none!important;
  }

  #footer .col-md-3:nth-child(2) .collapse {
    display: block;
  }

  #footer .col-md-3:nth-child(2) .collapse.show {
    display: none;
  }



  .page-header {
    h1 {
      font-size: 1.5rem !important;
    }
  }

  .category-product-title {
    font-size: 1.5rem !important;
  }

  #header {

    .header-top {
      display: none;
    }

    .left-mobile-menu {
      display: flex;

      .favorite-block {
        .header-t {
          display: none;
        }

        .header-ico {
          position: relative;

          #x13_favorites_count_nb {
            position: absolute;
            top: .7rem;
            left: .6rem;
            font-size: .8rem;
            font-weight: 700;
          }

          svg {
            width: 25px;
          }
        }
      }
    }

    #_mobile_cart {
      .blockcart {
        background: none;
        color: #000;

        .shopping-cart a {
          display: flex;
        }

        .basket-icon {
          position: relative;

          span {
            position: absolute;
            top: .4rem;
            left: 50%;
            transform: translateX(-50%);
            padding: .2rem;
            font-weight: 700;
            font-size: .75rem;
            color: #000;
          }

          svg {
            width: 2.5rem;
            height: 1.6rem;

            path {
              fill: #000;
            }
          }
        }
      }
    }
  }

  #mobile_top_menu_wrapper {
    .menu-sub__list {
      li a {
        span {
          color: #000;
        }
      }
    }
  }

  #index {

    #carousel {
      .slick-arrow {
        display: flex !important;
      }
      .slick-prev {
        left: 0 !important;
      }
      .slick-next {
        right: 0 !important;
      }
    }

    .slick-arrow {
      display: none !important;
    }

    #carousel {
      .slider-caption {
        max-width: 300px;
        left: 1.5rem;
      }

      .caption-description {
        .banner-info {
          .banner-title {
            font-size: .7rem !important;
          }

          .banner-description {
            font-size: 1rem !important;
          }

          .banner-btns {
            width: 100% !important;
            bottom: -3rem !important;

            input {
              padding: 1rem 1.2rem !important;
            }
          }
        }
      }
    }

    .heart-block {
      .heart-content {
        padding: 1rem 0;
        display: grid;

        .left-side {
          order: 1;

          .heart-price-block {
            .price-block {
              display: flex;
              margin: 0 auto;

              a, h2 {
                margin: 0 1rem;
              }

              .price {
                display: flex;
                align-items: center;
                margin-bottom: 0;
              }
            }
          }
        }

        .right-side {
          justify-content: center;

          img {
            max-height: 200px;
          }
        }

        .heart-description {
          font-size: .9rem;
          margin-bottom: 1rem;
        }

        .heart-params {
          margin-bottom: 1rem;
        }

        .heart-index {
          margin-bottom: 1rem;
        }

        .heart-price-block {
          .price-icon {
            display: none;
          }
        }
      }
    }

    .heart-block {
      .container {
        & > .col-lg-5 {
          .heart-content {
            border-top: 1px solid #e6e6e6;
          }
        }
      }
    }

    .heartware-block {
      .container {
        & > .col-lg-5 {
          .heart-content {
            border-top: 1px solid #9b1740;
          }
        }
      }
    }

    .featured-products {

      .product__card-desc {
        display: block;
      }

      .product-miniature {
        margin-bottom: 0 !important;
      }

      .products-section-title {
        padding-top: 1rem;
      }

      .products {
        margin-top: 1rem;
      }

      .index-mini {
        margin-bottom: 0;
      }

      .product-price-and-shipping {
        width: 50%;
        float: left;
        padding-top: .5rem;
      }

      .add-to-cart-mini {
        display: block;
        width: 50%;
        float: right;
        padding: 0 !important;
      }
    }

    .lastReviewsHome {
      .contents {
        .row {
          & > .col-lg-5 {
            display: none;
          }

          .review_cotnents {

            font-size: .75rem;
            line-height: normal;
            padding: 0 3.5rem 6rem 3.5rem !important;

            .h1 {
              font-size: 1.5rem;
            }

            .who {
              font-size: .9rem;
            }
          }
        }
      }
    }

    .about-products-block {
      margin-top: -4rem;

      &:before {
        display: none;
      }
    }

    .index-about-us {
      margin-top: 0;

      .about-us-title {
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }

      .index-left-side {
        margin-bottom: 1rem;

        .index-about-us-small {
          display: none;
        }
      }

      .index-about-us-description {
        font-size: .9rem;
      }

      .index-about-block-2 {
        .index-about-us-description {
          margin-bottom: 1rem;
        }
      }
    }

    #x13partners {
      .page-heading {
        margin-bottom: 0;
        padding-bottom: 1rem;
      }

      .partners__elem {
        img {
          width: 100px;
        }
      }
    }
  }

  #footer {
    margin-top: 2rem;
    padding-bottom: 24px;

    .block_newsletter {

      .footer__title {
        display: block;
        border-bottom: 1px solid #fff;
        width: 100%;
        padding: 15px;
      }

      .newsletter-content {
        padding: 0 15px !important;
      }
    }

    .copyright-block {
      margin-top: 1rem;

      .copyright {
        font-size: .8rem;
        width: 60%;
      }
    }
  }

  #category {
    #products {

      .products {
        .product-miniature {
          .card {
            .card-img-top {
              .thumbnail img {
                height: 120% !important;
              }
            }
          }

          .card-body {
            .product-title {
              min-height: 3rem;
            }
          }
        }
      }

      .add-to-cart-mini {
        display: block;
        padding: 0 !important;
        text-align: center;

        .quick-view {
          padding: .6rem 2rem !important;
        }
      }
    }
  }


  #product {

    #main {
      & > .row {
        .left-side {
          order: 3 !important;

          .product-information {
            .products-description-short {
              margin-bottom: 1rem !important;
            }

            .product-additional-info {
              .print {
                display: none !important;
              }

              .shipment-24, .ask-button {
                width: 50%;
              }
            }
          }
        }

        .col-md-6 {
          order: 2;
        }

        .tabs {
          order: 3;

          .product-main-desc-block2 {
            .size-block-desc {
              justify-content: center;
              padding: 0 !important;

              .size-block {
                & > .col-md-3 {
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .lastReviewsHome {
        .contents {
          .row {
            & > .col-lg-5 {
              display: none;
            }

            .review_cotnents {

              font-size: .75rem;
              line-height: normal;
              padding: 0 3.5rem 6rem 3.5rem !important;

              .h1 {
                font-size: 1.5rem;
              }

              .who {
                font-size: .9rem;
              }
            }
          }
        }
      }

      .featured-products {

        .slick-arrow {
          display: none !important;
        }

        .product__card-desc {
          display: block;
        }

        .product-miniature {
          margin-bottom: 0 !important;
        }

        .products-section-title {
          padding-top: 0 !important;
        }

        .products {
          margin-top: 1rem;
        }

        .index-mini {
          margin-bottom: 0;
        }

        .product-price-and-shipping {
          width: 50%;
          float: left;
          padding-top: .5rem;
        }

        .add-to-cart-mini {
          display: block;
          width: 50%;
          float: right;
          padding: 0 !important;
        }
      }
    }

    #new_comment_form {
      width: 300px;

      .product {
        .product_desc {
          width: 260px;
        }
      }

      .new_comment_form_content {
        #criterions_list {
          li {
            text-align: center;

            label {
              margin: 0;
              float: initial;
            }

            .star_content {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }


    #wrapper #content-wrapper #content {
      .images-container {
        .position-relative {
          .products-imagescover {
            & > .slick-arrow {
              display: flex !important;
            }
            .slick-prev {
              left: 0 !important;
            }
            .slick-next {
              right: 0 !important;
            }
          }
        }
      }
    }

    .product-main-desc-block iframe {
      width: 280px;
      height: 200px;
    }
  }

  #contact {

    #content {
      .contact-form:before {
        right: 0 !important;
        background-size: contain;
      }
    }

    .contact-forms-group {
      .form-group {
        width: 100% !important;
      }
    }

    .contact-send-block {
      flex-wrap: wrap;
    }

    #footer {
      .contact-form-info-blocks > .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;

        .contact-form-info-desc {
          padding: 0 .5rem;
        }
      }
    }
  }


  #cms {

    #content {
      .faq-blocks {

        .faq-block {
          padding: .2rem !important;

          .faq-block-img {
            height: 0 !important;
          }

          p {
            font-size: .4rem !important;
            margin-top: 1.6rem !important;
          }
        }
      }

      .accordion-container {
        .faq-title {
          margin-top: 2rem;
          font-size: 1.2rem;
        }

        .accordion-box {
          .accordion-header {
            font-size: .9rem;
            padding-right: 4rem;
          }

          .accordion-content {
            font-size: .8rem;
          }
        }
      }
    }
  }

  #module-x13favoriteproducts-view {
    #x13_favorites {
      .products {

        .product-name {
          a {
            margin-right: 0 !important;
          }
        }

        .product-name img {
          max-width: 100% !important;
        }

        .aval-th, .product-title, .product-aval {
          display: none !important;
        }
      }
    }
  }
  #cart {
    .cart-summary {
      .checkout {
        position: static !important;
        background: none !important;
      }
    }
  }
}


@media (max-width: 360px) {

  #index {
    #carousel {
      .slider-caption {
        max-width: 280px;
      }
    }
  }
}

@media (max-width: 350px) {

  #index {
    #carousel {
      .slider-caption {
        max-width: 260px;
      }
    }

    .heart-block > .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  #product {
    .comments_note {

      & > span {
        padding: 0 .5rem !important;
      }

      .open-comment-form {
        padding-left: .5rem !important;
        padding-right: 0 !important;
        text-align: left;
      }

      img {
        padding-right: .4rem !important;
      }
    }
  }
}


@media (max-width: 320px) {

  #index {

    #carousel {
      .slider-caption {
        max-width: 240px;

        .caption-description {
          .banner-info {
            .banner-btns {
              input {
                padding: 1rem .5rem !important;
              }
            }
          }
        }
      }
    }

    .heart-block {
      .heart-price-block {
        .price-block {
          .price, .heart-link {
            margin: 0 .4rem !important;
          }
        }
      }
    }

    .featured-products {
      .products-section-title {
        font-size: 1.5rem;
      }
    }
  }

  #category {
    #products {
      .product-miniature {
        .add-to-cart-mini {
          .quick-view {
            padding: .6rem 1rem !important;
          }
        }
      }
    }
  }


  #product {
    #main {
      .comments_note {
        .star-content, span, .open-comment-form {
          padding: 0 !important;
        }
      }
    }

    #new_comment_form {
      width: 250px;

      .product {
        .product_desc {
          width: 210px;
        }
      }
    }

    .product-main-desc-block iframe {
      width: 230px;
      height: 200px;
    }
  }

  #cms {

    .faq-blocks {
      display: none !important;
    }
  }


  #module-x13favoriteproducts-view {
    #x13_favorites {
      .products {

        .table th, .table td {
          padding: .5rem;
        }

        .product-name {
          a {
            margin-right: 0 !important;
          }
        }

        .product-name img {
          max-width: 100% !important;
        }

        .aval-th, .product-title, .product-aval {
          display: none !important;
        }
      }
    }
  }

}