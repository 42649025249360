* {
  font-family: Overpass, sans-serif;
}

html {
  scroll-behavior: smooth;
}

body:not(#contact) .contact-form-info-block {
  display: none;
}

body {
  overflow-x: hidden;
  background: #eee !important;
}

/*Prevent text selection*/
//span{
//  -webkit-user-select: none !important;
//  -moz-user-select: none !important;
//  -ms-user-select: none !important;
//}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
input:disabled{
  background-color:white !important;
}

input[type='number'] {
  -moz-appearance: textfield;/*For FireFox*/

  &::-webkit-inner-spin-button { /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}

@media (min-width: 1200px) {
  #index {

    #wrapper {
      max-width: 100%;

      #carousel {
        margin: 0 -15px;

        .slick-prev {
          left: 0 !important;

          &:hover i {
            background: #9b1740;
            color: #fff;
          }
        }

        .slick-next {
          right: 0 !important;

          &:hover i {
            background: #9b1740;
            color: #fff;
          }
        }



        .slick-list {
          .slick-slide {
            .rc {
              height: 500px;
              padding-top: 0 !important;

              .img-carousel {
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1366px) {
  #index #wrapper #carousel .slick-list .slick-slide .rc {
    height: 600px;
  }
}

#carousel {

  .slick-slide > div {
    line-height: 0;
  }

  button {
    padding: 0;

    .left {
      background: #eeeeee;
      border-radius: 0;
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
      color: #000;
      font-size: 2.5rem;
      text-shadow: none;
    }

    .right {
      background: #eeeeee;
      border-radius: 0;
      border-bottom-left-radius: 50%;
      border-top-left-radius: 50%;
      color: #000;
      font-size: 2.5rem;
      text-shadow: none;
    }

    .left:hover, .right:hover {
      color: #9b1740;
    }
  }

  .slider-caption {
    top: 0;
    left: 16rem;
    display: flex;
    align-items: center;
    max-width: 488px;

    .caption-description {
      padding: 1rem 1rem 3rem 1rem;
      background: rgba(#fff, .8);
      border-radius: 12px;

      .banner-info {
        position: relative;

        .banner-title {
          color: #000;
          font-family: Overpass, sans-serif;
          font-size: 1.125rem;
          font-weight: 300;
        }

        .banner-description {
          font-size: 2rem;
          font-weight: 700;
          color: #000;
          line-height: normal;
        }

        .banner-btns {
          display: flex;
          width: 70%;
          justify-content: space-around;
          position: absolute;
          bottom: -5rem;

          form input {
            background: #9b1740;
            border: none;
            border-radius: 6px;
            color: #fff;
            font-size: 1rem;
            font-weight: 700;
            padding: 1.2rem 1.7rem;
            transition: all .3s;

            &:hover {
              background: #fff;
              color: #9b1740;
            }
          }
        }
      }
    }
  }
}

.heart-block {

  color: #fff;
  background: #7a1937;
  margin-bottom: 0 !important;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("../img/tla/pink-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  //&:after {
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  background-image: url("../img/tla/bottom-heartmate1.png");
  //  background-repeat: no-repeat;
  //  width: 100%;
  //  height: 100%;
  //}

  .container {
    display: flex;
    z-index: 3;

    .heartmate-bg {
      position: relative;

      //&:before {
      //  content: "";
      //  position: absolute;
      //  bottom: 0;
      //  right: -16px;
      //  background-image: url("../img/tla/right-heartmate.png");
      //  background-repeat: no-repeat;
      //  width: 50%;
      //  height: 100%;
      //  z-index: 1;
      //}
    }

    .col-lg-7 .heart-content {
      display: flex;
      padding: 4rem 0;
      justify-content: space-between;

      .left-side, .right-side {
        z-index: 1;
      }
    }

    .col-lg-5 {
      padding-right: 0;
      position: relative;

      //&:before {
      //  content: "";
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  height: 50%;
      //  width: 100vw;
      //  background: #861c3d;
      //}

      .heart-content {
        display: flex;
        padding: 4rem 0;
        padding-bottom: 0;
        justify-content: space-between;
        position: relative;

        .left-side {

          .heart-cat {
            color: #fff;
            font-size: .875rem;
            border: 1px solid #fff;
            border-radius: 200px;
            padding: .3rem .5rem;
          }

          .heart-title {
            font-family: Overpass, sans-serif;
            font-size: 1.375rem;
            font-weight: 700;
            margin: .7rem 0 0 0;
          }

          .heart-index {
            font-size: .875rem;
            font-weight: 300;
            margin-bottom: 2rem;
          }

          .heart-price-block {
            display: flex;

            .price-block {
              .price {
                font-size: 1.5rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
              }

              .heart-link {
                color: #9b1740;
                font-size: 1rem;
                font-weight: 700;
                background: #fff;
                border-radius: 6px;
                padding: .8rem 1rem;
                transition: all .3s;

                &:hover {
                  opacity: .7;
                }
              }
            }

            .price-icon {
              display: flex;
              align-items: flex-end;
              padding-left: 1rem;
            }
          }
        }

        .right-side {
          display: flex;
          align-items: center;

          img {
            max-width: 180px;
          }
        }
      }
    }

    .col-lg-5 .heart-content:last-child {
      padding-top: 2rem;
    }
  }

  .heartmate-bg {

    .heart-content {
      display: flex;
      padding: 4rem 0;
      justify-content: space-between;

      .left-side {
        max-width: 330px;

        .heart-cat {
          color: #fff;
          font-size: .875rem;
          border: 1px solid #fff;
          border-radius: 200px;
          padding: .3rem .5rem;
        }

        .heart-title {
          font-family: Overpass, sans-serif;
          font-size: 1.375rem;
          font-weight: 700;
          margin: .7rem 0 1.5rem 0;
        }

        .heart-description {
          font-size: 1rem;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 2rem;
        }

        .heart-params {
          margin-bottom: 2rem;

          p {
            margin-bottom: 0;
          }

          .heart-param {
            display: flex;

            .param {
              font-size: 1rem;
              font-weight: 700;
              line-height: 24px;
              width: 30%;
            }

            .param-desc {
              font-size: 1rem;
              font-weight: 300;
              line-height: 24px;
            }
          }
        }

        .heart-price-block {
          display: flex;

          .price-block {
            .price {
              font-size: 1.875rem;
              font-weight: 700;
              margin-bottom: 1.5rem;
            }

            .heart-link {
              color: #9b1740;
              font-size: 1rem;
              font-weight: 700;
              background: #fff;
              border-radius: 6px;
              padding: .8rem 1rem;
              transition: all .3s;

              &:hover {
                opacity: .7;
              }
            }
          }

          .price-icon {
            display: flex;
            align-items: flex-end;
            padding-left: 3rem;
          }
        }
      }

      .right-side {
        display: flex;
        align-items: flex-end;
      }
    }
  }
}

.heartware-block {

  color: #000 !important;
  margin-bottom: 2rem !important;
  background: #e4e4e4;

  &:before {
    background-image: url("../img/tla/grey-bg.png") !important;
  }

  //&:after {
  //  background-image: url("../img/tla/top-heartware.png") !important;
  //}

  .container {

    //.col-lg-5:before {
    //  background: #dddddd !important;
    //}

    .heartmate-bg {
      position: relative;

      //&:before {
      //  content: "";
      //  position: absolute;
      //  bottom: 0;
      //  right: -16px;
      //  background-image: url("../img/tla/right-heartware.png") !important;
      //  background-repeat: no-repeat;
      //  width: 50%;
      //  height: 100%;
      //  z-index: 1;
      //}
    }

    .heart-cat {
      color: #000 !important;
      border: 1px solid #000 !important;
    }

    .heart-price-block {
      .heart-link {
        color: #fff !important;
        background: #9b1740 !important;

        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

.slick-arrow > i {
  display: flex;
  align-items: center;
  height: 63px;
  border-radius: 100px !important;
  width: 23px;
  font-size: 1.5rem !important;
  color: #000;
  border: 1px solid #e7e7e7;
  background: #fff;
  transition: all .3s;

  &:hover {
    background: #9b1740;
    color: #fff;
  }
}

.slick-prev {
  left: -3rem !important;
}

.slick-next {
  right: -3rem !important;
}

.separator {
  width: 70px;
  height: 2px;
  background: #9b1740;
  margin: 0 auto;
}


.featured-products {

  .products-section-title {
    font-family: Overpass, sans-serif;
    font-size: 1.875rem;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 0;
    padding-top: 2rem;
  }

  max-width: 1140px;
  margin: 0 auto;


  .product-miniature {

    padding: 0 20px;

    .card-product {
      border: none;
      background: none;
      position: relative;

      &:hover .product-description .product-title a {
        color: #9b1740 !important;
      }

      .card-img-top {
        overflow: visible;
      }

      .card-img-top a {
        background: none;
        background-image: url("../img/tla/product-bg.png") !important;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: visible;

        img {
          position: absolute;
          bottom: -3rem;
        }
      }

      .card-body {
        position: relative;
        padding: 3rem 0 1.25rem 0;

        .product-description {
          justify-content: normal;
          .product-title {
            text-align: left;
            margin-bottom: 0;
            a {
              color: #000;
              font-weight: 700;
              font-size: 1.125rem;
              font-family: Overpass, sans-serif;
            }
          }
          .index-mini {
            font-family: Overpass, sans-serif;
            color: #000;
            font-size: .875rem;
            font-weight: 300;
          }
          .product-price-and-shipping {
            text-align: left !important;

            .price {
              color: #9b1740;
              font-size: 1.5rem;
              font-weight: 700;
              font-family: Overpass, sans-serif;
            }
          }
          .add-to-cart-mini {

            padding: .5rem 0 !important;
            .quick-view {
              color: #fff;
              font-weight: 700;
              font-size: 1rem;
              font-family: Overpass, sans-serif;
              background: #9b1740;
              padding: .6rem 1.5rem !important;
              border-radius: 6px;
              transition: all .3s;

              &:hover {
                color: #9b1740;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }

  .products {
    margin-top: 3rem;

    .slick-list {
      .slick-track {

      }
    }
  }
}

.lastReviewsHome{
  color: #fff;

  .lSAction {
    a {
      top: calc(50% - 55px);
      transform: translateY(-50%);
      margin-top: 0;
      opacity: 1;

      &:hover {
        opacity: .7;
      }
    }
  }

  #lastReviews {
    background: none;
    border-bottom: none;

    .reviewBlock {
      border-top: none;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 110px);
        width: 100%;
        //background: #9b1740;
        background-image: url("../img/tla/testi-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 12px;
      }

      .contents {
        padding: 0;
        .review_cotnents {
          font-family: Overpass, sans-serif;
          font-size: .9375rem;
          font-weight: 300;
          line-height: 23px;
          padding: 0 0 7rem 7rem;
          min-height: 320px;

          .products-section-title {
            font-family: Overpass, sans-serif;
            font-size: 1.75rem;
            font-weight: 700;
            text-transform: none !important;
            padding: .5rem 0;
            text-align: unset;
          }
          .who {
            font-family: Overpass, sans-serif;
            font-size: 1rem;
            font-weight: 700;
            padding: 0 5rem 1rem 0;
          }

          .stars {
            display: block;
            float: none;
            padding: .5rem 0;
          }
        }
        .col-lg-5 {
          text-align: center;
          position: relative;

          a {
            position: absolute;
            bottom: 0;
            left: 0;

            img {
              width: 70%;
              height: auto;
            }
          }
        }
      }
    }
  }
}

.about-products-block {
  position: relative;
  margin-bottom: 0 !important;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -3rem;
    background-image: url("../img/tla/about-product-bg.png");
    background-repeat: no-repeat;
    width: 100vw;
    height: 120%;
  }

  .about-product-items {
    margin-top: 4rem;
  }

  .row {
    .col-md-12 {
      .about-products-title {
        font-family: Overpass, sans-serif;
        font-size: 1.875rem;
        font-weight: 700;
        color: #000;
        text-align: center;
        margin-bottom: 0;
      }

      .about-product-description {
        font-family: Overpass, sans-serif;
        font-size: 1rem;
        font-weight: 300;
        color: #000;
        line-height: 1.5rem;
        text-align: center;
        margin-top: 2rem;
        padding: 0 1rem;
      }
    }

    .about-product-item {
      text-align: center;
      margin-bottom: 3rem;

      .img-box {

        height: 80px;
        margin-bottom: 2rem;
        img {
          max-height: 100%;
        }
      }

      h3 {
        font-family: Overpass, sans-serif;
        font-size: 1.125rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 1rem;
      }
      p {
        font-family: Overpass, sans-serif;
        font-size: .875rem;
        font-weight: 300;
        color: #000;
        line-height: 1.25rem;
        padding: 0 .9rem;
      }
    }
  }
}

.index-about-us {
  background: #9b1740;
  margin: 0 -15px;
  margin-top: 6rem;
  margin-bottom: 0 !important;
  color: #fff;
  font-family: Overpass, sans-serif;
  position: relative;


  &:before {
    content: "";
    position: absolute;
    top: -7rem;
    left: -4rem;
    background-image: url("../img/tla/index-about-top.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: calc(100% - 4rem);
    height: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: -5rem;
    right: -26rem;
    background-image: url("../img/tla/index-about-top-right.png");
    background-position: right top;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .container {
    padding-top: 3rem;

    .index-about-us-block {

      .index-about-us-block-first {
        justify-content: space-between;
        margin-bottom: 3rem;
        .index-left-side {

          .index-about-us-logo {
            position: relative;
            top: -3rem;
          }
          .about-us-title {
            margin-bottom: 5rem;
            font-family: Overpass, sans-serif;
            font-size: 1.563rem;
            font-weight: 400;
          }
        }
        .index-right-side {
          z-index: 1;
          .index-about-us-big {
            margin-bottom: 3rem;
          }
          .index-about-us-description {
            font-family: Overpass, sans-serif;
            font-size: 1rem;
            font-weight: 400;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
    .index-about-us-block-1 {
      margin-bottom: 3.5rem;
      .row {
        justify-content: space-between;

        .index-left-side {
          padding-right: .8rem;

          .index-about-us-description:nth-child(1) {
            margin-bottom: 3rem;
          }
          .index-about-us-description:last-child {
            margin-bottom: 0;
          }

          .index-about-us-description {
            font-family: Overpass, sans-serif;
            font-size: 1rem;
            font-weight: 400;
          }
        }
        .index-right-side {
          z-index: 3;
          .index-about-us-big {
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .index-about-block-2 {

      & > .col-md-12 {
        z-index: 3;
      }

      .index-about-us-description {
        margin-bottom: 3rem;
      }
    }
  }
}

#index {
  #footer {
    margin-top: -1rem;
    &:before {
      height: 200%;
    }
    &:after {
      display: none;
    }

    .footer-left-bg {
      display: none;
    }

    .footer-container {
      .footer-blocks {
        &:before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #50061e;
          opacity: .45;
        }
      }
    }
  }
}

#contact #footer .contact-block-bg {
  margin-top: 5rem;
}

#contact #footer {
  margin-top: 2rem;
}


#index #footer .contact-block-bg:before {
  height: 220%;
}

* #footer {

  background-color: transparent;

  .contact-form-info-block {
    margin-top: 0;
    .col-md-12 {

      & > .separator {
        margin: 0 auto;
      }

      .contact-form-info-title {
        text-align: center;
        font-family: Overpass, sans-serif;
        font-size: 1.875rem;
        font-weight: 700;
        color: #000;
        margin-bottom: 0;
      }
    }
  }

  .contact-form-info-blocks {
    margin-top: 3rem;
    color: #000;

    .separator {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 1rem;
    }

    .contact-form-info-subtitle {
      font-family: Overpass, sans-serif;
      font-size: .9rem;
      font-weight: 700;
      margin-bottom: 0;
    }

    .contact-mail1 {
      margin-bottom: .5rem !important;
    }

    .contact-form-info-desc {
      font-family: Overpass, sans-serif;
      font-size: .875rem;
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 26px;
    }
  }

  .contact-block-bg {

    background: #9b1740;
    color: #fff;
    position: relative;
    z-index: 2;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: -15rem;
      background-image: url(../img/tla/footer-bg-circle.png);
      background-repeat: no-repeat;
      background-position: right top;
      width: 100%;
      height: 80%;
    }

    &:after {
      content: "";
      position: absolute;
      top: -5rem;
      left: 0;
      background-image: url(../img/tla/footer-top-bg.png);
      background-repeat: no-repeat;
      z-index: -1;
      width: 100%;
      height: 80%;
    }

    .footer-left-bg {
      position: absolute;
      top: -3rem;
      left: 0;
      background-image: url(../img/tla/footer-left-bg.png);
      background-repeat: no-repeat;
      width: 100%;
      height: 108%;
    }
  }

  margin-top: 6rem;


  .footer-container {
    .container {
      padding-top: 2rem;
      position: relative;

      .footer-blocks {

        &:before {
          display: none;
        }

        .col-md-3:nth-child(1) {
          ul {
            display: grid;

            li:nth-child(1) {
              order: 1;
            }
            li:nth-child(2) {
              order: 5;
            }
            li:nth-child(3) {
              order: 6;
            }
            li:nth-child(4) {
              order: 2;
            }
            li:nth-child(5) {
              order: 3;
            }
            li:nth-child(6) {
              order: 4;
            }
            li:nth-child(7) {
              order: 7;
            }
          }
        }

        .col-md-3 {
          .footer__title {
            font-family: Overpass, sans-serif;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: none;
            color: #fff;
            margin-bottom: 1.5rem;
          }
          ul {

            li {
              display: flex;
              align-items: center;
            }

            li i {
              margin-right: .3rem;
              font-size: .8rem;
            }

            li a {
              color: #fff;
              font-size: 1rem;
              font-weight: 400;
              font-family: "Open-sans", sans-serif;
              line-height: 32px;

              &:hover {
                opacity: .7;
              }
            }
          }
        }
        .block_newsletter {

          .newsletter-content {
            padding-left: 0;
            padding-right: 3rem;
          }
          form {
            .input-group {
              .form-control {
                background: none;
                box-shadow: none;
                border: none;
                border-bottom: 1px solid #fff;
                color: #fff;
                padding-left: 0;
                font-family: Overpass, sans-serif;
                font-weight: 400;

                &::placeholder {
                  color: #fff;
                }
              }
              #gdpr_consent {
                margin-top: 1rem;

                .custom-control-label {
                  font-family: Overpass, sans-serif;
                  font-size: .875rem;
                  font-weight: 400;

                  &:before {
                    background: none;
                    border: 1px solid #fff;
                    border-radius: 3px;
                    box-shadow: none;
                  }

                  a {
                    text-decoration: underline;
                    color: #fff;

                    &:hover {
                      opacity: .7;
                    }
                  }
                }
              }
              .input-group-append {
                margin-top: 2rem;
                button {
                  background: #fff;
                  color: #9b1740;
                  font-family: Overpass, sans-serif;
                  font-size: .9375rem;
                  font-weight: 700;
                  border: none;
                  border-radius: 6px;
                  padding: .5rem 1.5rem;
                  box-shadow: none;

                  &:hover {
                    opacity: .7;
                  }
                }
              }
            }
          }

          .alert {
            font-family: Overpass, sans-serif !important;
          }

          .alert-success {
            color: #000;
            background-color: lightgray;
          }
        }
        .block-contact {
          .footer-info {
            .desc_footer {

              .info p {
                margin-bottom: 0;
              }

              .info {
                .address-info {
                  font-size: 1rem;
                  font-family: Overpass, sans-serif;
                  font-weight: 400;
                  color: #fff;
                }
                .info-description {
                  font-size: .875rem;
                  font-family: Overpass, sans-serif;
                  font-weight: 300;
                }
              }

              #mail {
                display: flex !important;

                &:before {
                  width: 40px;
                  height: auto;
                  content: "";
                  background-image: url(../img/tla/mail.png);
                  background-repeat: no-repeat;
                  background-size: auto;
                  display: inline-block;
                  margin-right: 1rem;
                  background-position: center;
                }
              }
              #address {
                display: flex !important;
                margin: 2rem 0;

                &:before {
                  width: 40px;
                  height: auto;
                  content: "";
                  background-image: url(../img/tla/addres.png);
                  background-repeat: no-repeat;
                  background-size: auto;
                  display: inline-block;
                  margin-right: 1rem;
                  background-position: center;
                }
              }
              .facebook-btn {
                background: #fff;
                color: #0050cb;
                font-family: Overpass, sans-serif;
                font-size: 1.063rem;
                font-weight: 300;
                padding: .6rem 1.5rem;
                border-radius: 6px;

                &:hover {
                  opacity: .7;
                }

                img {
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }
      .copyright-block {
        position: relative;
        height: 3rem;
        display: flex;
        align-items: center;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #50061e;
          opacity: .45;
        }

        p, a {
          color: #fff;
          font-size: .875rem;
          font-weight: 400;
          font-family: Overpass, sans-serif;
          margin: 0;
        }

        .copyright-info-block {
          display: flex;
          justify-content: space-between;

          a:hover {
            opacity: .7;
          }
        }
      }
    }
  }
}

#x13partners {
  background: #9b1740;
  margin: 0 -15px;

  .page-heading-bg {
    .products-section-title {
      font-family: Overpass, sans-serif;
      font-size: 1.75rem;
      font-weight: 700;
      text-transform: none !important;
      padding: 2rem 0;
      color: #fff;
    }
  }

  .partners__list {

    position: relative;
    z-index: 3;
    button {
      color: #000;
    }

    .slick-list {
      margin: 0 -15px;

      .slick-track {
        display: flex;

        .slick-slide {
          height: auto;
          padding: 0 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > div {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 .5rem;
          }

          .partners__elem {
            width: auto !important;

            &:hover {
              opacity: .7;
            }

            img {
              width: 150px;
              height: auto;
            }
          }
        }
      }
    }
  }
}


#contact {

  #wrapper {

    .breadcrumb {
      display: none;
    }
    & > .row {
      justify-content: center;
      #left-column {
        display: none;
      }
      #content-wrapper {

        #main {
          margin-top: 3rem;
          background: none;
          border: none;

          .contact-form {
            position: relative;

            &:before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: -5%;
              right: -28%;
              background-image: url("../img/tla/contact-bg.png");
              background-repeat: no-repeat;
              background-position: right top;
              opacity: .05;
              z-index: -1;
            }
            .needs-validation {
              width: 100%;
              max-width: 100%;

              .form-fields {
                h1 {
                  font-family: Overpass, sans-serif;
                  color: #000;
                  font-size: 2rem;
                  font-weight: 700;
                  text-transform: none;
                  margin-bottom: 0;
                }
                .separator {
                  margin: 0 auto;
                }
                .contact-form-desc {
                  padding-top: 1rem;
                  padding-bottom: 2rem;
                  text-align: center;
                }
                .contact-forms-group {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;

                  .form-group {
                    width: 48%;
                    margin-bottom: 1.5rem;

                    .form-control, .custom-select {
                      border: 1px solid #b4b4b4;
                      font-size: .875rem;
                      font-weight: 400;
                      color: #000;
                      background-color: transparent;
                      padding: 1rem;

                      &:focus {
                        box-shadow: none;
                        border-color: #9b1740;
                        color: #9b1740;
                      }
                      &::placeholder {
                        font-family: Overpass, sans-serif;
                        color: #000;
                      }
                    }

                    input, .custom-select {
                      height: 61px;
                    }
                  }
                  .form-group:last-child {
                    width: 100%;
                  }
                }
                & > .form-group {
                  .custom-checkbox {
                    font-size: .875rem;
                    font-weight: 400;
                    color: #000;

                    .custom-control-label {
                      box-shadow: none !important;

                      &:before {
                        box-shadow: none;
                        border-color: #b4b4b4;
                      }
                    }

                    .custom-control-input:checked ~ .custom-control-label::before {
                      background-color: #9b1740;
                      border-color: #9b1740;
                      box-shadow: none;
                    }
                  }
                }

                .contact-send-block {
                  display: flex;
                  justify-content: space-between;
                }

                .btn {
                  background: #9b1740 !important;
                  border-color: #9b1740;
                  border-radius: 6px;
                  color: #fff;
                  box-shadow: none !important;
                  transition: all .3s;
                  font-family: Overpass, sans-serif;
                  font-size: 1rem;
                  font-weight: 700;

                  &:hover {
                    background: transparent !important;
                    color: #000;
                  }
                  &:disabled {
                    background: #9b1740 !important;
                    border-color: #9b1740;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


#cms {

  #wrapper {
    & > .visible--desktop {
      display: none;
    }
    & > .row {
      margin-top: 2rem;

      #main {
        background: none;
        border: none;

        .page-header--cms {
          padding: 0;
          border: none;
          background: none;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 2rem;
            height: 1px;
            background: #9b1740;
          }
          h1 {
            font-family: Overpass, sans-serif;
            font-size: 1.875rem;
            font-weight: 700;
            color: #000;
          }
        }
        .visible--desktop {
          .breadcrumb {

            .breadcrumb-item {
              a {
                color: #676767;

                &:hover {
                  color: #d2879e;
                }
              }
            }
            .active {
              &:before {
                color: #000;
              }

              span {
                color: #000;
              }
            }
          }
        }
        #content {
          .faq-blocks {
            display: flex;

            .faq-block {
              width: 100%;
              text-align: center;
              background-image: url("../img/tla/faq-block-bg.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: contain;
              padding: 1.2rem;

              .faq-block-img {
                width: 100%;
                height: 80px;

                img {
                  max-width: 100%;
                  height: auto;
                }
              }
              p {
                margin-bottom: 0;
                margin-top: 1rem;
                font-family: Overpass, sans-serif;
                font-size: 1rem;
                font-weight: 700;
                color: #fff;

                a {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .accordion-container {
    font-family: Overpass, sans-serif;
    margin-bottom: 2rem;

    .faq-title {
      font-size: 2.25rem;
      color: #000;
      font-weight: 400;
      margin-top: 5rem;
      margin-bottom: 0;
      text-transform: none;
    }

    .separator {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    .accordion-box {
      border-radius: 5px;
      border: 1px solid #b4b4b4;
      margin: 1rem 0;

      .accordion-header {
        font-size: 1.25rem;
        font-family: Overpass, sans-serif;
        margin: 5px 0 0 0;
        cursor: pointer;
      }

      .accordion-header {
        font-family: Overpass, sans-serif;
        font-size: 1.25rem;
        color: #000;
        font-weight: 400;
        padding: 2rem 20px;
        position: relative;
        margin-top: 0;

        &:after {
          content: '';
          float: right;
          background: #9b1740 url("../img/tla/faq-arrow-down.png") no-repeat 50% 1.5rem / 1rem;
          position: absolute;
          top: 0;
          right: 2rem;
          width: 2rem;
          height: 3rem;
          border-bottom-left-radius: 100px;
          border-bottom-right-radius: 100px;
        }
      }

      .active-header {
        font-weight: 700;
        color: #fff;

        &:after {
          content: '';
          background: #fff url("../img/tla/faq-arrow-up.png") no-repeat 50% 1.5rem / 1rem;
          position: absolute;
          top: 0;
          right: 2rem;
          width: 2rem;
          height: 3rem;
          border-bottom-left-radius: 100px;
          border-bottom-right-radius: 100px;
        }
      }

      .accordion-content {
        display: none;
        padding: 20px;
        width: 100% !important;


        p {
          color: #000;
          font-family: "Roboto", sans-serif;
          font-size: 1rem;
          font-weight: 400;
        }
      }
      .active-header, .open-content {
        background: #9b1740;
      }
      .open-content {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: -1rem;
          width: 95%;
          height: 1px;
          background: #fff;
          opacity: .22;
        }
        p {
          font-family: Overpass, sans-serif;
          font-size: 1rem;
          font-weight: 300;
          color: #fff;
          opacity: 1;
          margin-bottom: 0;
        }
      }
    }
  }
}


#my-account {

  #wrapper {
    margin-top: 2rem;

    #main {
      .page-header--my-account {
        h1 {
          text-transform: none;
          font-family: Overpass, sans-serif;
          color: #000;
        }
      }
      #content {
        .account-links {
          a {
            color: #000;

            &:hover {
              color: #9b1740;

              svg path {
                stroke: #9b1740;
              }
            }

            .icon-heart-o {
              margin-bottom: 0;
            }

            svg path {
              stroke: #000;
            }
          }
        }
      }
      .page-footer--my-account {
        a {
          color: #9b1740;

          &:hover {
            color: #000;
          }
        }
      }
    }
  }
}

#identity, #addresses, #history, #order-slip, #module-psgdpr-gdpr, #module-ps_emailalerts-account {

  .breadcrumb {
    .active {
      span {
        color: #000;
      }
    }
  }

  .form-control-submit {
    background: #9b1740;
    border-color: #9b1740;
    color: #fff;
    font-family: Overpass, sans-serif;

    &:hover {
      background: none;
      color: #9b1740;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #9b1740;
    border-color: #9b1740;
    box-shadow: none;
  }

  .address-footer a {
    color: #9b1740;

    &:hover {
      color: #000;
    }
  }

  .addresses-footer a {
    color: #9b1740;
    border-color: #9b1740;

    &:hover {
      background: #9b1740;
      color: #fff;
    }
  }

  .order-actions a {
    color: #9b1740;
    border-color: #9b1740;

    &:hover {
      background: #9b1740;
      color: #fff;
    }
  }

  .psgdprinfo17 {
    #exportDataToCsv, #exportDataToPdf {
      background: #9b1740;
      border-color: #9b1740;
      color: #fff;

      &:hover {
        background: none;
        color: #9b1740;
      }
    }
    a {
      color: #9b1740;

      &:hover {
        color: #000;
      }
    }
  }

  .page-footer {
    a {
      color: #9b1740;

      &:hover {
        color: #000;
      }
    }
  }
}

#authentication, #password {
  #main {
    margin-top: 2rem;
    background: none;
    border: none;
    font-family: Overpass, sans-serif;

    .form-control {
      box-shadow: none !important;

      &:focus {
        border-color: #9b1740;
      }
    }

    .page-header {
      background: none;
      border: none;
      text-align: center;

      h1 {
        text-transform: none;
        font-family: Overpass, sans-serif;
      }
    }
    .form-group_forgot-password {
      a {
        color: #9b1740;

        &:hover {
          color: #000;
        }
      }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #9b1740;
      border-color: #9b1740;
      box-shadow: none;
    }

    .form-fields {
      .form-control-submit {
        background: #9b1740;
        border-color: #9b1740;

        &:hover {
          background: none;
          color: #9b1740;
        }
      }
    }


    .form-footer {
      .form-control-submit {
        background: #9b1740;
        border-color: #9b1740;

        &:hover {
          background: none;
          color: #9b1740;
        }
      }
    }

    .register-form {
      a {
        color: #9b1740;

        &:hover {
          color: #000;
        }
      }
    }

    .page-footer {
      background: none;
      border: none;
      text-align: center;

      a {
        color: #9b1740;

        &:hover {
          color: #000;
        }
      }
    }
  }
}

#module-x13favoriteproducts-view {

  #wrapper {
    .visible--desktop {
      .breadcrumb {
        .active span {
          color: #000;
        }
      }
    }
  }

  #main {
    border: none;
    background: none;

    .x13fav-products-table {

      .delete-th {
        text-align: center;
      }

      tbody {

        tr:first-child {
          td {
            border-top: none;
          }
        }


        .delete-btn {
          text-align: center;

          p {
            color: #000;

            &:hover {
              color: #F95754;
            }
          }
        }

        .product-name {
          display: flex;

          .product-thumbnail {
            margin-right: 1rem;

            img {
              max-width: 50%;
            }
          }

          .product-title {
            a {
              color: #000;

              &:hover {
                color: #4ab8b8;
              }
            }
          }
        }

        .product-add {
          .add-to-cart-or-refresh {
            margin: 0;

            .add-to-cart {
              background: #9b1740;
              border: none;
              color: #fff;
              text-transform: uppercase;

              &:hover {
                opacity: .7;
              }
            }
          }
        }
      }
    }
  }
}